<template>
    <div>
        <multiselect v-model="inputVal.value"
                     :searchable="true"
                     :options="Object.keys(usersList)"
                     :multiple="inputVal.condition=='in'"
                     :custom-label="opt =>usersList[opt]"
        ></multiselect>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
        ...mapGetters('Users', ['getUsersList']),
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        usersList() {
            let list = {}
            this.getUsersList().forEach(item => {
                list[item.id] = item.fullname
            })

            return list
        }
    }
}
</script>